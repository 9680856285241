import React, { useState, useEffect, useRef, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
	ActivityTable,
	OptionLabel,
	Parameters,
	Execution,
} from './Components';
import { useTranslation } from 'react-i18next';
import {
	$Button,
	$AutoComplete,
	$Dropdown,
	$Drawer,
	$Popover,
} from 'us.common/components';
import { IActivity, IOption, IOptionItem } from 'us.common/interfaces';
import { Formik } from 'formik';
import { BoxIcons, ExclamationCircleFilled, IconTypes } from 'us.icons';
import { IActivityExecution, IGroupingOptions } from './Interfaces';
import {
	getActivityPlaceholder,
	getOptionWithGrouping,
	getOptionWithoutGrouping,
	getSelectedActivity,
	getSelectedActivityDisplayName,
	searchActivityByCodeOrName,

} from './Functions';
import {
	Mode,
	ActivityParametersState,
	KeyboardKey,
	EntityTypeShortForm,
	EVENT_ACTIVITY_TYPE,
} from 'us.common/constants';
import { activityExecutionActions } from 'us.common/actions';
import * as Azure from 'us.helper/azure';
import { ExecuteAction, ExecuteBulk, ExecuteSingle } from './Repository';
import { getFilterActivityByEntityType } from 'us.common/functions';
import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import './ActivityExecution.scss';
import { matchPath, useLocation } from 'react-router-dom';
import { DEFAULT_FORM_VALUES } from './Constants';

const { activities, parameters, activity } = activityExecutionActions;

/**
 * @description Activity Execution
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3083632702/Activity+Execution+UI+Implementation
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 16/08/2022
 */
const ActivityExecution: React.FC<PropsFromRedux & IActivityExecution> = (
	props
) => {
	const { t } = useTranslation();
	const { instance } = useMsal();
	const {
		isEventGrouping,
		isHideTable,
		isBulkExecutionEnabled,
		isDisabled,
		isGroupActivityOptions,
		isActivityOptionWithCode,
		groupingProperty,
		mode,
		activityAPIRequest,
		activities,
		parameters: parametersData,
		isHideDatePicker,
		activityFormDetail,
		executeActivityEndPoint,
		defaultData,
		entityId,
		entityType,
		selectedEntities,
		filter,
		externalData,
		isPopConfirmEnabled,
		propConfirmDetail,
		reduxActivities,
		isPopConfirmEnabledDropDown,
		isActionExecution,
		maxEntityCountExecuteAtOnce,
		isRowKeysAsEntityIds,
		bulkExecutionSource,
		activityExecutionMode,
		getParameters,
		updateParameterDefaultData,
		resetActivity,
		executeActivity,
		setActivityData,
		getActivities,
		navigateAfterExecution,
		setActivityMode,
		selectedCaseAndSubCase,
	} = props;

	const { data, isFetching } = activities;
	const { isEnableDropDown, list } = parametersData;

	const [tableVisible, setTableVisible] = useState<boolean>(false);
	const [searchedData, setSearchData] = useState<Array<IActivity>>([]);
	const [isOpenActivityDropdown, setIsOpenActivityDropdown] =
		useState<boolean>(false);
	const [selectedActivityVersion, setSelectedActivityVersion] =
		useState('');
	const activityRef = useRef<HTMLInputElement>();

	const { endPoint, parameters } = activityAPIRequest;
	const { entityState, entitytype } = useMemo(() => {
		return {
			entityState: parameters?.entitystate ?? '',
			entitytype: parameters?.entitytype ?? '',
		};
	}, [parameters]);

	const { initialValues } = activityFormDetail;
	const { activityId } = initialValues;
	const { optionsGrouping } = groupingProperty ?? {};

	const currentUser: any = (
		window._ENV.REACT_APP_AZURE_AD_SETUP
			? new Azure.ADAuth()
			: new Azure.B2CAuth()
	).currentUser();

	const { pathname } = useLocation();

	const { params }: any = matchPath(pathname, {
		path: '/:type/:id?/:module?',
		exact: false,
		strict: false,
	});

	useEffect(() => {
		document.addEventListener('keydown', setFocus, true);
		setActivityMode && setActivityMode({ mode });

		return () => {
			setActivityMode &&
				setActivityMode({ mode: Mode.Initial });
			document.removeEventListener('keydown', setFocus, true);
		};
	}, []);

	useEffect(() => {
	
		if (mode == Mode.Initial) {
			handleGetActivities();
		}
		resetActivity && resetActivity({});
	}, [entityState, entitytype, activityExecutionMode]);

	useEffect(() => {

		if (!isFetching) {
			const filteredActivity: Array<IActivity> =
				getFilterActivityByEntityType(data, filter);
			setSearchData(filteredActivity);
		}
	}, [data, filter]);

	useEffect(() => {
		if (activityId) {
			activityId &&
				getParameters &&
				getParameters({
					activityId,
					state: entityState
						? entityState
						: ActivityParametersState.DefaultState,
				});
		}
	}, [activityId]);

	useEffect(() => {
		toggleBodyOverlay(isEnableDropDown);
	}, [isEnableDropDown]);

	/**
	 * @description - Handle Fetch activities with ot without parameters
	 */
	const handleGetActivities = () => {
		if (endPoint) {
			if (
				entitytype &&
				[
					EntityTypeShortForm.CASE as string,
					EntityTypeShortForm.SUB_CASE as string,
				].includes(entitytype)
			) {
				if (entityState != '' && entityType != '') {
					getActivities &&
						getActivities({
							endPoint,
							parameters,
							tabLevel: params[
								'type'
							],
						});
				}
			} else {
				getActivities &&
					getActivities({
						endPoint,
						parameters: parameters ?? {},
						tabLevel: params['type'],
					});
			}
		}
	};

	/**
	 * @function
	 * @description set focus into activity bar
	 * @param {any} e event data
	 */
	const setFocus = (e: any) => {
		if (e.altKey && e.key == KeyboardKey.a) {
			activityRef.current && activityRef.current.focus();
		}
	};

	/**
	 * @function
	 * @description  get a option data for autocomplate dropdown
	 * @param {string} title activity name
	 * @param {string | number} code activity code
	 * @returns {IOptionItem} a option data for activities dropdown
	 */
	const getOptionItem = (
		title: string,
		code: number | string,
		version: string,
		isVersionVisible: boolean,
		type: string,
		activityId:number
	): IOptionItem => {
		try {
		
			return {
				key: activityId.toString() + version,
				value: `${title}_${version}_${activityId}`,
				activityId: activityId,
				label: (
					<OptionLabel
						name={title} 
						code={code}
						version={version}
						isVersionVisible={
							isVersionVisible
						}
					/>
				),
				type,
				version: version,
			};
		} catch (error) {
			return {
				key: '',
				value: '',
				activityId: -1,
				label: <></>,
				version: '',
				type: "",
			};
		}
	};

	/**
	 * @function
	 * @description  get all activities options
	 * @returns {Array<IOption | IOptionItem>} all options data for activity dropdown
	 */
	const getOptions = (): Array<IOption | IOptionItem> => {
		try {
			if (searchedData.length > 0) {
				if (isGroupActivityOptions || isEventGrouping) {
					if (isActivityOptionWithCode) {
						return [
							...getOptionWithGrouping(
								searchedData,
								optionsGrouping,
								isEventGrouping
							).map(
								({
									label,
									groupingOptions,
								}: IGroupingOptions) => ({
									label: t(
										`US.COMMON:ACTIVITY_EXECUTION.${label.toUpperCase()}`
									),
									options: groupingOptions.map(
										({
											displayName,
											activityCode,
											activityVersion,
											isVersionVisible,
											type,
											activityId
										}: IActivity) =>
											getOptionItem(
												displayName,
												activityCode,
												activityVersion,
												isVersionVisible,
												type,
												activityId
											)
									),
								})
							),
						].filter(
							(option: IOption) =>
								option.options
									.length >
								0
						);
										
					} else {
						return [
							...getOptionWithGrouping(
								searchedData,
								optionsGrouping
							).map(
								({
									label,
									groupingOptions,
								}: IGroupingOptions) => ({
									label: t(
										`US.COMMON:ACTIVITY_EXECUTION.${label.toUpperCase()}_ACTIVITIES`
									),
									options: getOptionWithoutGrouping(
										groupingOptions
									),
								})
							),
						];
					}
				} else {
					if (isActivityOptionWithCode) {
						return searchedData.map(
							({
								displayName,
								activityCode,
								activityVersion,
								isVersionVisible,
								type,
								activityId
							}: IActivity) =>
								getOptionItem(
									displayName,
									activityCode,
									activityVersion,
									isVersionVisible,
									type,
									activityId
								)
						);
					} else {
						return getOptionWithoutGrouping(
							searchedData
						);
					}
				}
			} else {
				return [];
			}
		} catch (error) {
			return [];
		}
	};

	/**
	 * @function
	 * @description handle onSelect activity
	 * @param {string} selectedActivity selectedActivity
	 */
	const onSelectActivity = (selectedActivity: string, record: any) => {
	const { version, type, activityId } = record ?? {};

		setSelectedActivityVersion(version);
		setActivityData &&
			setActivityData({
				activity: getSelectedActivityDisplayName(selectedActivity,isActivityOptionWithCode),
				activityId:activityId,
				executingDateTime: '',
				executingDateTimeWithParameter: '',
				action: '',
				type,
			});

		isOpenActivityDropdown && setIsOpenActivityDropdown(false);

		const { entitytype } =
			getSelectedActivity(data, activityId) ?? {};

		updateParameterDefaultData &&
			updateParameterDefaultData({
				entityType: entitytype,
				userName: currentUser?.unique_name,
			});
	};

	/**
	 * @function
	 * @description handle activity search
	 * @param {string} searchText - search value
	 */
	const onSearchActivity = (searchText: string): void => {
		const filteredActivity: Array<IActivity> =
			getFilterActivityByEntityType(data, filter);
		try {
			if (searchText == ' ' || searchText.trim().length > 0) {
				setIsOpenActivityDropdown(true);
				setSearchData(
					searchActivityByCodeOrName(
						filteredActivity,
						searchText
					)
				);
			} else {
				setSearchData(filteredActivity);
			}
		} catch (error) {
			setSearchData(filteredActivity);
		}
	};

	/**
	 * @description handling style
	 */
	const overLayElement: HTMLElement = document.getElementById(
		'hiddenOverlay'
	) as HTMLElement;
	const addBodyClass = (className: any) =>
		document.body.classList.add(className);
	const removeBodyClass = (className: any) =>
		document.body.classList.remove(className);
	const toggleBodyOverlay = (show: any) =>
		show === true
			? addBodyClass('hidden-overlay-open')
			: removeBodyClass('hidden-overlay-open');

	/**
	 * @function
	 * @description handle activity execution
	 * @param formData activity form data
	 */
	const onExecute = (formData: any) => {
		let request: any;
		const actionRequest = isActionExecution
			? ExecuteAction.call(formData, selectedEntities)
			: {};
		if (isBulkExecutionEnabled) {
			request = ExecuteBulk.call(
				formData,
				list,
				data,
				defaultData,
				externalData,
				selectedEntities,
				isEnableDropDown,
				filter as string,
				isRowKeysAsEntityIds,
				bulkExecutionSource,
				selectedActivityVersion
			);
		} else {
			request = ExecuteSingle.call(
				formData,
				list,
				data,
				defaultData,
				entityId,
				entityType,
				isEnableDropDown,
				filter,
				selectedActivityVersion
			);
		}
		executeActivity &&
			executeActivity(
				{ request, endPoint: executeActivityEndPoint },
				{
					navigateAfterExecution,
					isBulkExecutionEnabled,
					reduxActivities,
					actionRequest,
					user:
						instance.getActiveAccount()
							?.username ?? '',
				}
			);
	};

	/**
	 * @function
	 * @description handle focus of activity autoComplete
	 */
	const handleOnFocusActivity = () => {
		if (mode == Mode.Click) {
			handleGetActivities();
		}
	};

	const setScheduleDateTime = (
		activityDetail: any
	): {
		executingDateTime: any;
		executingDateTimeWithParameter: any;
	} => {
		try {
			const { isEdit, scheduledTime, recordType } =
				activityDetail ?? {};
			if (isEdit && recordType != 'executed') {
				const time = moment(scheduledTime);
				return {
					executingDateTime: isEnableDropDown
						? ''
						: time,
					executingDateTimeWithParameter:
						isEnableDropDown ? time : '',
				};
			} else {
				return {
					executingDateTime: '',
					executingDateTimeWithParameter: '',
				};
			}
		} catch (error) {
			return {
				executingDateTime: '',
				executingDateTimeWithParameter: '',
			};
		}
	};

	return (
		<Formik
			initialValues={
				mode == activityExecutionMode
					? {
							...initialValues,
							...setScheduleDateTime(
								initialValues
							),
							isEnableDropDown,
					  }
					: DEFAULT_FORM_VALUES
			}
			onSubmit={onExecute}
			enableReinitialize>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				setFieldValue,
				...rest
			}: any) => (
				<$Popover
					content={
						<div
							style={{
								maxWidth: '300px',
							}}>
							<div className='ant-popover-message'>
								<ExclamationCircleFilled />
								<div className='ant-popover-message-title'>
									<span>
										{t(
											'US.COMMON:ACTIVITY_EXECUTION.EXECUTION_OF_ACTIVITIES_ARE_NOT_POSSIBLE_WHEN_BOTH_CASES_AND_SUB_CASES_ARE_SELECTED'
										)}
									</span>
								</div>
							</div>
						</div>
					}
					trigger='hover'
					placement='topLeft'
					overlayClassName={
						isDisabled &&
						selectedCaseAndSubCase.data
							?.length != 0
							? 'show-aeb-popover'
							: 'hide-aeb-popover'
					}>
					<div
						className={`activity-bar ${
							isHideTable
								? 'no-sa-btn'
								: ''
						}`}>
						<div className='activity-bar-select'>
							{!isHideTable && (
								<$Button
									data-testid='execution-activityTable'
									type='link'
									className='activity-bar-select-before'
									onClick={() =>
										setTableVisible(
											true
										)
									}
									disabled={
										isDisabled ||
										data.length ==
											0
									}>
									<BoxIcons
										type={
											IconTypes.BOX_ICON
										}
										name='select-activity'
									/>
								</$Button>
							)}
							<$AutoComplete
								key='key'
								style={{
									width: 340,
								}}
								placeholder={getActivityPlaceholder(
									mode,
									isBulkExecutionEnabled,
									data.length >
										0,
									false
								)}
								name='activity'
								ref={
									activityRef
								}
								virtual={false}
								options={getOptions()}
								onSelect={(
									value: string,
									record: any
								) =>
									onSelectActivity(
										value,
										record
									)
								}
								className='activity-bar-select-input mb-0'
								allowClear={
									true
								}
								disabled={
									isDisabled
								}
								onSearch={(
									value: string
								) =>
									onSearchActivity(
										value
									)
								}
								onFocus={
									handleOnFocusActivity
								}
								open={
									isOpenActivityDropdown
								}
								data-testid='activity-testId'
								onDropdownVisibleChange={() =>
									isOpenActivityDropdown &&
									setIsOpenActivityDropdown(
										false
									)
								}
								onClear={() =>
									resetActivity &&
									resetActivity(
										{}
									)
								}
								popupClassName='activity-bar-select-dropdown'
								tabIndex={1}
							/>
							<$Dropdown
								getPopupContainer={() =>
									overLayElement
								}
								overlay={
									<Parameters
										mode={
											mode
										}
										isDisabled={
											isDisabled
										}
										isHideDatePicker={
											isHideDatePicker
										}
										isPopConfirmEnabled={
											isPopConfirmEnabledDropDown
										}
										propConfirmDetail={
											propConfirmDetail
										}
										isActionExecution={
											isActionExecution
										}
										maxEntityCountExecuteAtOnce={
											maxEntityCountExecuteAtOnce
										}
										selectedEntities={
											selectedEntities
										}
									/>
								}
								placement='bottomLeft'
								trigger={[
									'click',
								]}
								arrow
								visible={
									values.isEnableDropDown
								}>
								<span className='parameters-dropdown-btn'></span>
							</$Dropdown>
						</div>
						<Execution
							name='executingDateTime'
							hasParameters={false}
							isDisabled={
								isDisabled ||
								values.isEnableDropDown
							}
							isHideDatePicker={
								isHideDatePicker
							}
							isPopConfirmEnabled={
								isPopConfirmEnabled
							}
							propConfirmDetail={
								propConfirmDetail
							}
							tabStartedIndex={1}
							isActionExecution={
								isActionExecution
							}
							maxEntityCountExecuteAtOnce={
								maxEntityCountExecuteAtOnce
							}
							selectedEntities={
								selectedEntities
							}
							mode={mode}
							isDatePickerDisabled={initialValues?.type == EVENT_ACTIVITY_TYPE}
						/>
						<$Drawer
							title={t(
								'US.COMMON:ACTIVITY_EXECUTION.SELECT_ACTIVITY'
							)}
							width={1100}
							visible={tableVisible}
							onClose={() =>
								setTableVisible(
									false
								)
							}
							destroyOnClose
							className='select-activity'>
							<ActivityTable
								dataSource={getFilterActivityByEntityType(
									data,
									filter
								)}
								groupingProperty={
									groupingProperty!
								}
								onClose={() =>
									setTableVisible(
										false
									)
								}
								handleSelect={
									onSelectActivity
								}
							/>
						</$Drawer>
					</div>
				</$Popover>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: any) => {
	const { activityExecution, common, casesAndSubCases } = state;
	const {
		activities,
		parameters,
		activityFormDetail,
		defaultData,
		activityExecutionMode,
	} = activityExecution;
	const { selectedCaseAndSubCase } = casesAndSubCases;
	const { currentDateFormat } = common;

	return {
		activities,
		parameters,
		currentDateFormat,
		activityFormDetail,
		defaultData,
		activityExecutionMode,
		selectedCaseAndSubCase,
	};
};

const mapDispatchToProps = {
	getActivities: activities.get,
	getParameters: parameters.get,
	updateParameterDefaultData: parameters.updateDefaultData,
	setActivityData: activity.set,
	executeActivity: activity.execute,
	manageDropDown: parameters.mangeParameterDropDown,
	resetActivity: activity.reset,
	setActivityMode: activity.setMode,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ActivityExecution);
